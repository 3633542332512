

































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class AssetNewSite extends Vue {
  @Prop() showModal = false;
  @Prop() onHideModal!: Function;
  @Prop() onSubmitSuccess!: Function;

  $refs!: {
    name: HTMLInputElement;
  };

  // variables
  name = "";
  nameError = "";

  description = "";
  descriptionError = "";

  address = "";
  addressError = "";

  suite = "";
  suiteError = "";

  city = "";
  cityError = "";

  state = "";
  stateError = "";

  pin = "";
  pinError = "";

  country = "";
  countryError = "";

  isLoading = false;

  @Watch("showModal")
  watchShowModal() {
    if (this.showModal) {
      setTimeout(() => {
        this.$refs.name.focus();
      }, 10);
    }
  }

  @Watch("name")
  watchname() {
    this.nameError = "";
  }

  @Watch("description")
  watchdescription() {
    this.descriptionError = "";
  }

  @Watch("address")
  watchaddress() {
    this.addressError = "";
    // this.searchLocation(this.address);
  }

  @Watch("suite")
  watchsuite() {
    this.suiteError = "";
  }

  @Watch("city")
  watchcity() {
    this.cityError = "";
  }

  @Watch("state")
  watchstate() {
    this.stateError = "";
  }

  @Watch("pin")
  watchpin() {
    this.pinError = "";
    if (this.pin.length === 6) {
      this.searchLocation(this.pin);
    }
  }

  @Watch("country")
  watchcountry() {
    this.countryError = "";
  }

  async searchLocation(query) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/utils/map?search=${query}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const data = await response.json();
        if (data.length > 0) {
          this.state = data[0].state;
          this.city = data[0].city;
          this.country = data[0].country;
        } else {
          this.$store.commit("showToastMessage", {
            boldText: "Error!",
            text: "Location not found, Try using pin-code",
            type: "Danger",
          });
        }
      } else {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: "Something went wrong!",
          type: "Danger",
        });
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  resetModal() {
    this.name = "";
    this.description = "";
    this.address = "";
    this.suite = "";
    this.city = "";
    this.state = "";
    this.pin = "";
    this.country = "";
  }

  async onSubmitNewAssetSite() {
    try {
      this.isLoading = true;
      const data = {
        name: this.name,
        description: this.description,
        address: this.address,
        suite: this.suite,
        city: this.city,
        state: this.state,
        pin: this.pin,
        country: this.country,
      };
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/site`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.status == 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Successfully!",
          text: "Site Added",
          type: "Success",
        });
        this.onHideModal();
        this.resetModal();
        this.onSubmitSuccess();
      } else if (response.status == 400) {
        const error = await response.json();
        switch (error[0].path[0]) {
          case "name":
            this.nameError = error[0].message;
            break;
          case "description":
            this.descriptionError = error[0].message;
            break;
          case "address":
            this.addressError = error[0].message;
            break;
          case "suite":
            this.suiteError = error[0].message;
            break;
          case "city":
            this.cityError = error[0].message;
            break;
          case "state":
            this.stateError = error[0].message;
            break;
          case "pin":
            this.pinError = error[0].message;
            break;
          case "country":
            this.countryError = error[0].message;
            break;
          default:
            break;
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
      this.onHideModal();
      this.resetModal();
    } finally {
      this.isLoading = false;
    }
  }
}
