




























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class AssetNewSite extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  @Prop() showModal = false;
  @Prop() allSites: any = [];
  @Prop() onHideModal!: Function;
  @Prop() onSubmitSuccess!: Function;

  $refs!: {
    name: HTMLInputElement;
  };

  // variables
  name = "";
  nameError = "";

  site_id = "";
  site_idError = "";

  isLoading = false;

  @Watch("showModal")
  onChangeModal() {
    if (this.showModal) {
      setTimeout(() => {
        this.$refs.name.focus();
      }, 10);
      if (this.allSites.length > 0) {
        this.site_id = this.allSites[0].id;
      }
    }
  }

  @Watch("name")
  watchname() {
    this.nameError = "";
  }

  @Watch("site_id")
  watchsite_id() {
    this.site_idError = "";
  }

  resetModal() {
    this.name = "";
    this.site_id = "";
  }

  async onSubmitNewAssetSite() {
    try {
      this.isLoading = true;
      const data = {
        name: this.name,
        site_id: this.site_id,
      };
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/location`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.status == 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Successfully!",
          text: "Location Added",
          type: "Success",
        });
        this.onHideModal();
        this.resetModal();
        this.onSubmitSuccess();
      } else if (response.status == 400) {
        const error = await response.json();
        switch (error[0].path[0]) {
          case "name":
            this.nameError = error[0].message;
            break;
          case "site_id":
            this.site_idError = error[0].message;
            break;
          default:
            break;
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
      this.onHideModal();
      this.resetModal();
    } finally {
      this.isLoading = false;
    }
  }
}
