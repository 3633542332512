import { render, staticRenderFns } from "./Asset-NewLocation.vue?vue&type=template&id=4cb394da&scoped=true&"
import script from "./Asset-NewLocation.vue?vue&type=script&lang=ts&"
export * from "./Asset-NewLocation.vue?vue&type=script&lang=ts&"
import style0 from "./Asset-NewLocation.vue?vue&type=style&index=0&id=4cb394da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cb394da",
  null
  
)

export default component.exports